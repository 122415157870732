<template>
  <loading-component
    v-if="loading"
  />
  <error-component
    v-else-if="error"
    :error="error"
  />
  <app-form-layout v-else>
    <template #top>
      <campaign-status-admin />
    </template>

    <template #left>
      <name-form
        type="캠페인"
        inject-key="currentCampaign"
        read-only
      />
      <campaign-date-selector read-only />
      <button-selector-form
        title="캠페인 유형"
        data-key="currentCampaign"
        model-key="chargeType"
        option-key="chargeOptions"
        guide-id="popover-charge-type"
        read-only
      />
      <guide-popover
        target="popover-charge-type"
        title="캠페인 유형"
      >
        CPM : 고객에게 노출되는 만큼 과금됩니다. 브랜드 인지도를 높이고 싶은 광고주님께 적합한 캠페인 유형입니다.<br><br>
        CPC : 고객이 해당 캠페인을 클릭하여 방문했을 때 과금됩니다. 매출을 향상하거나 고객의 특정 행동을 유도할때 추천드립니다.
      </guide-popover>
    </template>

    <template #right>
      <campaign-daily-cost read-only />
      <button-selector-form
        title="레이아웃"
        data-key="currentCampaign"
        model-key="layout"
        option-key="layoutOptions"
        guide-id="popover-layout"
        read-only
      />
      <guide-popover
        target="popover-layout"
        title="레이아웃"
      >
        채널별로 지원하는 레이아웃에 차이가 있을 수 있습니다.<br>
        이미지 광고 : 광고주님이 업로드 하신 이미지가 광고로 노출됩니다.<br>
        동영상 광고 : 동영상을 지원하는 광고 구좌에서 광고주님이 업로드 하신 영상을 통해 광고가 노출됩니다.<br>
        검색 광고 : 채널의 검색창에서 관련 키워드 검색시 추천 URL로 노출됩니다.<br>
      </guide-popover>
      <campaign-landing-url read-only />
    </template>

    <template #middle>
      <campaign-contents read-only />
    </template>

    <template
      v-if="isPending"
      #submit-button
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-2 mt-2"
        block
        :disabled="!isAcceptable"
        @click="onAccept"
      >
        승인하기
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-2 mt-2"
        block
        :disabled="isAcceptable"
        @click="onReject"
      >
        반려하기
      </b-button>
    </template>
  </app-form-layout>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BButton } from 'bootstrap-vue'
import useCampaign from '@/views/utils/campaign/useCampaign'
import {
  computed, onMounted, provide, reactive, toRefs,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import AppFormLayout from '@/views/layouts/AppFormLayout.vue'
import NameForm from '@/views/components/NameForm.vue'
import ButtonSelectorForm from '@/views/components/ButtonSelectorForm.vue'
import CampaignDateSelector from '@/views/components/campaign/CampaignDateSelector.vue'
import CampaignDailyCost from '@/views/components/campaign/CampaignDailyCost.vue'
import CampaignLandingUrl from '@/views/components/campaign/CampaignLandingUrl.vue'
import CampaignContents from '@/views/components/campaign/CampaignContents.vue'
import CampaignStatusAdmin from '@/views/components/campaign/CampaignStatusAdmin.vue'
import useAdminTask from '@/views/utils/task/useAdminTask'

export default {
  components: {
    BButton,
    AppFormLayout,
    NameForm,
    ButtonSelectorForm,
    CampaignStatusAdmin,
    CampaignDateSelector,
    CampaignDailyCost,
    CampaignLandingUrl,
    CampaignContents,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      setCurrentCampaign,
      currentCampaign,
      makeCampaignData,
    } = useCampaign()

    const {
      loading,
      error,
      fetchCampaign,
      reviewCampaign,
    } = useAdminTask()

    const { route, router } = useRouter()

    const { reviewStatus } = currentCampaign
    const isPending = computed(() => reviewStatus.value === 'PENDING')
    const newRejectHistory = toRefs(reactive({
      category: [],
      description: '',
    }))
    provide('newRejectHistory', newRejectHistory)

    const onAccept = async () => {
      const review = {
        result: 'ACCEPTED',
      }
      const campaign = makeCampaignData()
      await reviewCampaign(campaign, review)
      router.go(-1)
    }

    const onReject = async () => {
      const campaign = makeCampaignData()
      const review = {
        result: 'REJECTED',
        category: newRejectHistory.category.value.join(','),
        description: newRejectHistory.description.value,
      }
      await reviewCampaign(campaign, review)
      router.go(-1)
    }

    const isAcceptable = computed(() => newRejectHistory.category.value.length === 0 && !newRejectHistory.description.value)

    onMounted(async () => {
      const { campaignId } = route.value.params
      const newCampaign = await fetchCampaign(campaignId)
      setCurrentCampaign(newCampaign)
    })

    return {
      loading,
      error,
      onAccept,
      onReject,
      isPending,
      isAcceptable,
    }
  },
}
</script>
